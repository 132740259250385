export const CliniciansPositions = {
  doctor: "Doctor",
  nurse: "Nurse",
  pharmacist: "Pharmacist",
  paramedic: "Paramedic",
  physiotherapist: "Physiotherapist",
};

export const ClinicianStatuses = {
  undefined: "Undefined",
  new: "New Clinician",
  compliant: "Clinician compliant",
  partiallyCompliant: "Clinician partially compliant",
  noncompliant: "Clinician noncompliant",
};

export const CasesStatuses = {
  groupReview: "Group review needed",
  reviewNeeded: "Review needed",
  clinicalConcern: "Clinical concern",
  compliant: "Compliant",
  partiallyCompliant: "Partially compliant",
  noncompliant: "Noncompliant",
  clinicalQuery: "Clinical query",
  forReflection: "For reflection",
  forReflectionReplyNeeded: "For reflection - reply needed",
  forReflectionResponseReceived: "For reflection - response received",
};

export const CasesStatusesIcons = {
  [CasesStatuses.groupReview]: "mdi-account-multiple",
  [CasesStatuses.appropriate]: "mdi-check",
  [CasesStatuses.compliant]: "mdi-check",
  [CasesStatuses.partiallyCompliant]: "mdi-alert",
  [CasesStatuses.noncompliant]: "mdi-alert",
  [CasesStatuses.reviewNeeded]: "mdi-alert-circle",
  [CasesStatuses.clinicalConcern]: "mdi-alert",
  [CasesStatuses.clinicalConcernReplyNeeded]: "mdi-alert",
  [CasesStatuses.clinicalConcernResponseReceived]: "mdi-alert",
  [CasesStatuses.clinicalQuery]: "mdi-note-text-outline",
  [CasesStatuses.forReflection]: "mdi-autorenew",
  [CasesStatuses.forReflectionReplyNeeded]: "mdi-autorenew",
  [CasesStatuses.forReflectionResponseReceived]: "mdi-autorenew",
};

export const ClinicianCasesColors = {
  safe: "#4f75ff",
  unsafe: "#ff8f8e",
  acceptable: "#ffd463",
};

export const CliniciansStatusColors = {
  [ClinicianStatuses.new]: "#2E7CF6",
  [ClinicianStatuses.compliant]: "#01C6D0",
  [ClinicianStatuses.partiallyCompliant]: "#FF9B63",
  [ClinicianStatuses.noncompliant]: "#FF8F8E",
  [ClinicianStatuses.undefined]: "#E0E0E0",
  none: "#0C0C0C",
};

export const CasesStatusColors = {
  [CasesStatuses.groupReview]: "#CAD084",
  [CasesStatuses.appropriate]: "#4f75ff",
  [CasesStatuses.compliant]: "#01C6D0",
  [CasesStatuses.partiallyCompliant]: "#FF9B63",
  [CasesStatuses.noncompliant]: "#FF8F8E",
  [CasesStatuses.clinicalConcern]: "#FF8F8E",
  [CasesStatuses.clinicalConcernReplyNeeded]: "#FFD463",
  [CasesStatuses.clinicalConcernResponseReceived]: "#FF9B63",
  [CasesStatuses.clinicalQuery]: "#FF0000",
  [CasesStatuses.forReflection]: "#747CFF",
  [CasesStatuses.forReflectionReplyNeeded]: "#FF8EED",
  [CasesStatuses.forReflectionResponseReceived]: "#01C6D0",
  [CasesStatuses.reviewNeeded]: "#BABABA",
};

export const CasesAuditStatusColors = {
  [CasesStatuses.compliant]: "#249904",
  [CasesStatuses.partiallyCompliant]: "#efa508",
  [CasesStatuses.noncompliant]: "#fa5756",
};

export const TableRowsPerPage = [10, 20, 50];

export const UserRoles = {
  admin: "ADMIN",
  doctor: "CLINICIAN",
  auditor: "AUDITOR",
  manager: "MANAGER",
};

export const GroupReviewEvents = {
  started: "started",
  statusUpdate: "statusUpdate",
  resolutionSent: "resolutionSent",
  replySent: "replySent",
};

export const PaginationDirections = {
  next: "next",
  previous: "prev",
};

export const CaseAuditTypes = {
  consultation: "consultation",
  advice: "advice",
  all: "all",
};

export const AuditCaseViews = {
  special: "special",
  clinician: "clinician",
  groupReview: "groupReview",
};

export const UserVerificationStatuses = {
  emailVerified: "emailVerified",
  emailNotVerified: "emailNotVerified",
  notVerified: "notVerified",
};

export const UserStatusesForView = {
  [UserVerificationStatuses.emailVerified]: "EMAIL VERIFIED",
  [UserVerificationStatuses.emailNotVerified]: "EMAIL NOT VERIFIED",
  [UserVerificationStatuses.notVerified]: "NOT VERIFIED",
};

export const VerificationStatusColors = {
  [UserVerificationStatuses.notVerified]: "gray gray-4",
  [UserVerificationStatuses.emailVerified]: "green lighten-4",
  [UserVerificationStatuses.emailNotVerified]: "deep-orange lighten-4",
};

export const BatchStatuses = {
  opened: "Opened",
  closed: "Closed",
  generated: "Generated",
};

export const BatchTypes = {
  audit: "Clinician Audit",
  groupReview: "Group Review",
  specialAudit: "Special Audit",
  docReview: "Clinical Doc.",
  general: "General Audit",
};

export const AdminManagementActions = {
  activateUser: "activateUser",
  deactivateUser: "deactivateUser",
};

export const AuditTemplateTypes = {
  target: "target",
  score: "score",
  general: "general",
};

export const AuditOrderTypes = {
  additional: "additional",
  primary: "primary",
};

export const AuditMessageTypes = {
  resolutionNote: "resolutionNote",
  reply: "reply",
  conclusion: "conclusion",
  communicationToClinician: "communicationToClinician",
  communicationToAuditor: "communicationToAuditor",
};

export const AuditStatuses = {
  open: "open",
  complete: "complete",
};

export const PagesBreadCrumbTitles = {
  batches: "Audit list",
  clinicians: "Clinicians",
  docAudit: "Clinical Documentation Audit",
  specialAudit: "Special Audit",
  cases: "Cases",
};

export const RoutesMapping = {
  [PagesBreadCrumbTitles.batches]: "/batches",
  [PagesBreadCrumbTitles.clinicians]: "/clinicians",
  [PagesBreadCrumbTitles.docAudit]: "/doc-audit",
  [PagesBreadCrumbTitles.specialAudit]: "/special-audit",
  [PagesBreadCrumbTitles.cases]: "/cases",
};

export const AuthenticationPages = {
  login: "login",
  reset: "reset",
  forgot: "forgot",
};

export const ImportMessageTypes = {
  casesImported: "casesPartImported",
  resultChunk: "resultChunk",
  importError: "error",
};

export const GeneralAuditOptionMapping = {
  Yes: 1,
  No: 0,
  "N/A": -1,
  Comment: -1,
};

export const GeneralAuditOptions = {
  comment: "Comment",
};

export const GeneralAuditOptionsArray = [
  ["Yes", "No"],
  ["Yes", "No", "Comment"],
  ["Yes", "No", "N/A"],
  ["Yes", "No", "N/A", "Comment"],
  ["Comment"],
];

export const CLinicalDocOptionsArray = [
  ["0", "1", "2", "N/A"],
];
